//
// Background-color
//

.bg-primary {
  background-color: $primary !important;
}

.bg-secondary {
  background-color: $secondary !important;
}

.bg-success {
  background-color: $success !important;
}

.bg-warning {
  background-color: $warning !important;
}

.bg-info {
  background-color: $info !important;
}

.bg-danger {
  background-color: $danger !important;
}

.bg-dark {
  background-color: $dark !important;
}

.bg-light {
  background-color: $light !important;
}

.bg-pink {
  background-color: $pink !important;
}

.bg-purple {
  background-color: $purple !important;
}

.bg-blue {
  background-color: $blue !important;
}
